import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import TaskApi, { IActivity } from "@api/task-api";
import { GlobalContext } from "@context/GlobalProvider";
import Loader from "../Loader";
import Select from "react-select";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import useDropdownChemical from "@hooks/useDropdownChemical";
import ModalFormChemical from "../ModalFormChemical";
import { IChemical, createNew } from "@api/chemical-api";

type Props = {
  data?: IActivity;
  daySelected?: string;
  onSubmit?: (data: IActivity) => any;
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => any;
};

const Activity: React.FC<Props> = ({
  data,
  daySelected,
  onSubmit,
  onCancel,
}) => {
  const [loader, showLoader] = useState(false);
  const { userStorage, ownerTask, setIsDataChanged } =
    useContext(GlobalContext);
  const [showModalChemical, setShowModalChemical] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [chemical, setChemical] = useState(data ? data?.material_txt : "");
  const [selectedChemical, setSelectedChemical] = useState("");
  const [modalFormChemical, showModalFormChemical] = useState(false);

  const { dataChemical, loadingChemical } = useDropdownChemical(isRefreshing);

  // console.log(userStorage);
  const handleSubmitTask = async (e: React.ChangeEvent<HTMLFormElement>) => {
    showLoader(true);
    e.preventDefault();

    const { activity_txt, material_txt, dose_txt, jumlah_txt, satuan_txt } =
      e.target;

    const payload = {
      activity_txt: activity_txt.value,
      material_txt: material_txt.value,
      dose_txt: dose_txt.value,
      jumlah_txt: jumlah_txt.value,
      satuan_txt: satuan_txt.value,
      time_calender_dte: daySelected,
    } as IActivity;

    payload["user_id_int"] = ownerTask.user_id_int;
    payload["land_code_var"] = ownerTask.land_code_var;
    payload["period_plant_txt"] = ownerTask.period_plant_txt;

    let res = null;
    if (!data) {
      payload["created_by_var"] = userStorage.fullname_var;
      payload["time_calender_dte"] = daySelected;
      res = await TaskApi.createNewActivity(payload);
    } else {
      console.log(payload);
      payload["updated_by_var"] = userStorage.fullname_var;
      payload["time_calender_dte"] = daySelected;
      res = await TaskApi.updateActivity(data.id_seq, payload);
      // toast.info('Update activity in proggress')
      // showLoader(false)
      // return
    }

    console.log("Activity :", res);
    if (res.data && res.data.code === 0) {
      toast.success(res.data.message);
      setIsDataChanged(true);
      // e.target.reset()
      if (onSubmit) onSubmit(payload);
      if (onCancel) onCancel();
    } else {
      showLoader(false);
      toast.error(res.data?.message || res.message);
    }
  };

  const onCloseModalChemical = () => {
    setShowModalChemical(false);
  };

  const handleReceiveDataFormChemical = async (data: IChemical) => {
    let res = null;
    res = await createNew(data);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        setIsRefreshing(!isRefreshing);
        showModalFormChemical(false);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  return (
    <>
      <form className="mt-5 md:px-4" onSubmit={handleSubmitTask}>
        <input
          name="id_seq"
          className="hidden"
          defaultValue={data?.id_seq || ""}
        />
        <div className="space-y-3">
          <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
            <div className="w-full">
              <label htmlFor="activity_txt" className="label-form">
                Aktivitas
              </label>
              <input
                name="activity_txt"
                id="activity_txt"
                className="input-task"
                defaultValue={data?.activity_txt || ""}
                placeholder="Penebaran benih + penutupan dengan jerami + Micoriza"
                required
              />
            </div>
          </div>
          <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
            <div className="w-full">
              <label htmlFor="material_txt" className="label-form">
                Bahan{" "}
                <button
                  type="button"
                  onClick={() => setShowModalChemical(true)}
                  className="ml-1 text-blue-600 underline">
                  Pilih Bahan
                </button>
              </label>
              <input
                name="material_txt"
                id="material_txt"
                className="input-task"
                value={chemical}
                onChange={(e) => setChemical(e.target.value)}
                placeholder="Benih POKOK"
                disabled
              />
            </div>
            <div className="w-full">
              <label htmlFor="dose_txt" className="label-form">
                Dosis
              </label>
              <input
                name="dose_txt"
                id="dose_txt"
                className="input-task"
                defaultValue={data?.dose_txt || ""}
                placeholder="35 kg benih"
              />
            </div>
          </div>
          <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
            <div className="w-full">
              <label htmlFor="jumlah" className="label-form">
                Jumlah HST
              </label>
              <input
                type="number"
                name="jumlah_txt"
                id="jumlah"
                className="input-task"
                defaultValue={data?.jumlah_txt || ""}
                placeholder="18"
                required
              />
            </div>
            <div className="w-full">
              <label htmlFor="satuan" className="label-form">
                Satuan HST
              </label>
              <input
                id="satuan"
                name="satuan_txt"
                className="input-task"
                defaultValue={data?.satuan_txt || ""}
                placeholder="ST"
                required
              />
            </div>
          </div>
        </div>
        <footer className="flex justify-end mt-7 space-x-2">
          <button
            type="reset"
            className="text-gray-700 px-6 py-2.5 rounded-lg text-sm"
            onClick={onCancel}>
            Batal
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm">
            Simpan
          </button>
        </footer>
      </form>

      {showModalChemical && (
        <>
          <div className="modal-form">
            <div
              className="modal-form-outside"
              onClick={onCloseModalChemical}
            />
            <div
              className="modal-form-content bg-white p-5 w-[95%] md:w-7/12 2xl:w-5/12"
              style={{ maxHeight: "90vh" }}>
              <div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
                <div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
                  <h3>Pilih Bahan Kimia</h3>
                </div>
                <button
                  type="button"
                  className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                  onClick={onCloseModalChemical}>
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div className="h-80">
                <div className="w-full mt-5">
                  <label htmlFor="chemical_id" className="label-form">
                    Nama Bahan Kimia
                    <button
                      type="button"
                      className="text-right text-blue-600"
                      onClick={() => showModalFormChemical(true)}>
                      + Tambah Bahan Kimia
                    </button>
                  </label>
                  <Select
                    id="chemical_id"
                    styles={selectStylesForm}
                    isClearable
                    placeholder="- Pilih bahan kimia -"
                    options={dataChemical}
                    isLoading={loadingChemical}
                    onChange={(data) =>
                      setSelectedChemical(data.map((v) => v.name).join(", "))
                    }
                    getOptionValue={(option: any) => option["id_seq"]}
                    getOptionLabel={(option: any) => option["name"]}
                    isMulti={true}
                  />
                </div>
                <footer className="flex justify-end mt-7 space-x-2">
                  <button
                    type="reset"
                    className="text-gray-700 px-6 py-2.5 rounded-lg text-sm"
                    onClick={onCloseModalChemical}>
                    Batal
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setChemical(selectedChemical);
                      setShowModalChemical(false);
                    }}
                    className="bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm">
                    Simpan
                  </button>
                </footer>
              </div>
            </div>
          </div>
          {modalFormChemical && (
            <ModalFormChemical
              data={null}
              onCancel={() => showModalFormChemical(false)}
              onSubmit={handleReceiveDataFormChemical}
            />
          )}
        </>
      )}

      {loader && <Loader />}
    </>
  );
};

export default Activity;
