import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import TableUser from "../../components/tables/Table";
import { ButtonAdd } from "../../components/button/CustomButton";
import { DropdownLand } from "../../components/dropdown/SimpleDropdown";
import Loader from "../../components/modal/Loader";
import FarmerGroupApi, { IFarmerGroup } from "../../api/farmergroup-api";
import { getAllProject, IProjectManagement } from "../../api/project-api";
import ModalFormFarmerGroup from "../../components/modal/ModalFormFarmerGroup";
import ModalDetailFarmerGroup from "../../components/modal/ModalDetailFarmerGroup";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { useLocation } from "react-router-dom";
import { IRecentPage } from "../../context/ContextInterface";
import { GlobalContext } from "../../context/GlobalProvider";
import { getPayloadRecentActivity } from "../../helpers/helper";

const TITLE_BAR = "User Management / Farmer Group";

type Props = {
  user?: any;
};

const FarmerGroupManagement: React.FC<Props> = ({ user }) => {
  const [loader, showLoader] = useState(false);
  const [modalForm, showModalForm] = useState(false);
  const [modalDetail, showModalDetail] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [originData, setOriginData] = useState<IFarmerGroup[] | []>([]);
  const [filterData, setFilterData] = useState<IFarmerGroup[] | []>([]);
  const [selectedData, setSelectedData] = useState<IFarmerGroup | null>(null);
  const [listProject, setListProject] = useState<IProjectManagement[]>([]);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await FarmerGroupApi.getAll();

    showLoader(false);
    console.log("ProbeType :", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    async function fetchAppDropdown() {
      const res = await getAllProject();
      if (res.data) {
        if (res.data.code === 0) {
          setListProject(res.data.data);
        }
      }
    }

    fetchData();
    fetchAppDropdown();
  }, [fetchData, dispatch]);

  const handleEditData = (selectedData: IFarmerGroup) => {
    setSelectedData(selectedData);
    setIsUpdate(true);
    showModalForm(true);
  };
  const handleDetailData = (selectedData: IFarmerGroup) => {
    setSelectedData(selectedData);
    setIsDetail(true);
    showModalDetail(true);
  };

  const handleReceiveDataForm = async (data: IFarmerGroup) => {
    showLoader(true);

    let res = null;
    if (!isUpdate) {
      data.created_by_var = user.fullname_var;
      res = await FarmerGroupApi.createNew(data);
      var payload = getPayloadRecentActivity(
        1,
        "Farmer Group",
        data.farmer_group_name_var,
        user.fullname_var,
        pathname,
      );
    } else {
      data.updated_by_var = user.fullname_var;
      res = await FarmerGroupApi.update(selectedData?.id_seq!, data);
      payload = getPayloadRecentActivity(
        2,
        "Farmer Group",
        data.farmer_group_name_var,
        user.fullname_var,
        pathname,
      );
    }

    console.log("Create/Update ProbeType :", res);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const handleDeleteData = async (data: IFarmerGroup) => {
    showLoader(true);
    const res = await FarmerGroupApi.delete(data.id_seq!);

    console.log("DELETE ProbeType :", res);
    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        const payload = getPayloadRecentActivity(
          3,
          "Farmer Group",
          data.farmer_group_name_var,
          user.fullname_var,
          pathname,
        );
        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const resetForm = () => {
    setSelectedData(null);
    setIsUpdate(false);
    showModalForm(false);
    showLoader(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return (
          item.farmer_group_name_var
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.project_code_var
            .toString()
            .includes(event.target.value.toLowerCase())
        );
      });

      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Nama Group Tani",
      accessor: "farmer_group_name_var",
    },
    {
      Header: "Projek",
      accessor: "project_code_var",
      Cell: ({ value, row }: any) => {
        return (
          listProject?.find((item) => item.project_code_var === value)
            ?.project_name_var || value
        );
      },
    },
    {
      Header: "Jumlah Anggota",
      accessor: "count_user",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }: any) =>
        parseInt(value) === 1 ? (
          <span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
            Active
          </span>
        ) : (
          <span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
            Inactive
          </span>
        ),
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        return (
          <DropdownLand
            data={data}
            onEdit={() => handleEditData(data)}
            onDelete={() => handleDeleteData(data)}
            onDetail={() => handleDetailData(data)}
          />
        );
      },
    },
  ];

  return (
    <div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
      <div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
        <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
          <h1 className="text-xl font-bold uppercase">List Farmer Group</h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField
              onChange={handleSearch}
              placeholder="Cari group tani..."
            />
            <ButtonAdd onClick={() => showModalForm(true)} />
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>

      {modalForm && (
        <ModalFormFarmerGroup
          data={selectedData!}
          projectList={listProject}
          onCancel={resetForm}
          onSubmit={handleReceiveDataForm}
        />
      )}
      {modalDetail && (
        <ModalDetailFarmerGroup
          data={selectedData!}
          projectList={listProject}
          onCancel={() => showModalDetail(false)}
        />
      )}
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(FarmerGroupManagement);
