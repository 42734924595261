import axios from "axios";
import { baseURL } from "./common-api";

export interface IProjectManagement {
  id_seq?: number;
  project_code_var: string;
  project_name_var: string;
  status_int?: number;
  user_id_int?: number | string;
  location?: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const getAllProject = async () => {
  try {
    const data = await axios.get(baseURL + "/api/project/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewProject = async (payload: IProjectManagement) => {
  try {
    const data = await axios.post(baseURL + "/api/project/create", payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateProject = async (
  projectID: number,
  payload: IProjectManagement,
) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/project/update/" + projectID,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteProject = async (projectID: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/project/delete/" + projectID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const autoFillProject = async (userID: number) => {
  try {
    const data = await axios.get(
      baseURL + "/api/land-commodity/dropdownProject?id_seq=" + userID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
