import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { QRCode } from "react-qrcode-logo";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getQRUrlContent,
  getQRUrlContentCombination,
} from "../api/inventory-api";
import appLogo from "../assets/img/logo-light.png";
import logo from "../assets/img/logo.png";
import Accordion from "../components/Accordion";
import { SkeletonQRDetail } from "../components/Skeleton";

type Props = {};

const QRProductDetail: React.FC<Props> = () => {
  const [data, setData] = useState<any>(null);
  const [loader, showLoader] = useState(true);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const qr = query.get("code");
  const isCombination = query.get("iscombination");

  useEffect(() => {
    (async function fetchData() {
      showLoader(true);

      let res;
      if (isCombination === "true") {
        res = await getQRUrlContentCombination(qr!);
      } else {
        res = await getQRUrlContent(qr!);
      }

      console.log(res);
      if (res.data && res.data.code === 0) {
        setData(res.data.data);
        showLoader(false);
      } else {
        toast.error(
          res.data?.message ||
            res.status + " " + res.message ||
            "Connection timeout"
        );
      }
    })();
  }, [qr, isCombination]);

  return (
    <>
      <div className="bg-soft min-h-screen flex justify-center md:items-center font-poppins">
        {loader ? (
          <SkeletonQRDetail />
        ) : (
          <div className="w-full md:w-[768px] bg-white rounded-xl shadow-2xl">
            <div className="bg-darkcayn md:rounded-xl flex justify-center py-6">
              <LazyLoadImage
                alt=""
                src={appLogo}
                effect="blur"
                className="w-48 cursor-pointer hover:opacity-70 ease-in-out duration-300"
                onClick={() =>
                  (window.location.href = window.location
                    .href!.split("/")
                    .slice(0, window.location.href!.split("/").length - 1)
                    .join("/"))
                }
              />
            </div>

            <div
              className="p-5 md:p-8 overflow-y-auto"
              style={{ maxHeight: "80vh" }}
            >
              <div className="flex flex-col-reverse md:flex-row items-center md:space-x-8 md:-mt-4">
                <div className="flex flex-col items-center md:items-start w-full mt-5 md:mt-0">
                  <p>Nama Produk</p>
                  {isCombination === "true" ? (
                    <h2 className="font-bold text-xl mb-4 mt-2 text-center">
                      [{data[0]?.id_product_var}]{" "}
                      {data[0]?.product_name_var || "-"}
                    </h2>
                  ) : (
                    <h2 className="font-bold text-xl mb-4 mt-2 text-center">
                      [{data?.id_product_var}] {data?.product_name_var || "-"}
                    </h2>
                  )}
                  <div className="w-full bg-green-200 text-green-700 rounded px-5 py-2 text-sms md:text-sm">
                    <span className="flex items-center italic">
                      <i className="fa-solid fa-info mr-4 flex justify-center items-center"></i>
                      {isCombination === "true" ? (
                        <p>
                          Terdiri dari{" "}
                          <span className="font-bold">
                            {data?.length} campuran
                          </span>{" "}
                          bahan/produk
                        </p>
                      ) : (
                        <p>
                          Terdiri dari <span className="font-bold">1 </span>{" "}
                          bahan/produk keluar
                        </p>
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <QRCode
                    value={qr!}
                    ecLevel="M"
                    enableCORS={true}
                    bgColor="#fff"
                    fgColor="#152e4d"
                    size={120}
                    quietZone={10}
                    qrStyle="squares"
                    logoImage={logo}
                  />
                  <p>{qr}</p>
                </div>
              </div>

              <hr className="border border-gray-200 w-full my-5" />

              <h2 className="text-lg text-lightcayn font-bold mb-3">
                Detail Produk
              </h2>
              {isCombination === "true" ? (
                <div>
                  {data &&
                    data?.map((item: any, key: number) => (
                      <Fragment key={key}>
                        <Accordion
                          title={`Bahan Campuran ${key + 1}`}
                          defaultOpen={key === 0}
                        >
                          <div className="grid grid-cols-1 md:grid-cols-3 text-sms md:text-sm">
                            <div className="col-span-2 grid grid-cols-2">
                              <div className="text-gray-500 space-y-3 flex flex-col">
                                <span>Nama Produk</span>
                                <span>Kode Unik</span>
                                <span>Komoditas</span>
                                <span>Metode Tanam</span>
                                <span>Mulai Tanam</span>
                                <span>Tanggal Panen</span>
                              </div>
                              <div className="space-y-3 flex flex-col text-right md:text-left">
                                <span>{item?.product_name_var || "-"}</span>
                                <span>{item?.unique_code_var || "-"}</span>
                                <span>{item?.commodity_name_var || "-"}</span>
                                <span>{item?.name_planting_var || "-"}</span>
                                <span>
                                  {item?.start_planting_dtm
                                    ? dayjs(item?.start_planting_dtm).format(
                                        "DD MMMM YYYY"
                                      )
                                    : "-"}
                                </span>
                                <span>
                                  {item?.harvest_on_dte
                                    ? dayjs(item?.harvest_on_dte).format(
                                        "DD MMMM YYYY"
                                      )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:space-y-2.5 grid grid-cols-2 md:flex flex-col">
                              <span className="font-semibold text-lightcayn">
                                Nama Petani
                              </span>
                              <span className="text-right md:text-left">
                                {item?.fullname_var || "-"}
                              </span>
                              <span className="font-semibold text-lightcayn mt-3 md:pt-3">
                                Pendamping (FA)
                              </span>
                              <span className="text-right md:text-left mt-3">
                                {item?.field_assistant_name_var || "-"}
                              </span>
                            </div>
                          </div>
                        </Accordion>
                        <br />
                      </Fragment>
                    ))}
                </div>
              ) : (
                <div>
                  <Accordion title={`Barang Keluar`} defaultOpen>
                    <div className="grid grid-cols-1 md:grid-cols-3 text-sms md:text-sm">
                      <div className="col-span-2 grid grid-cols-2">
                        <div className="text-gray-500 space-y-3 flex flex-col">
                          <span>Nama Produk</span>
                          <span>Kode Unik</span>
                          <span>Komoditas</span>
                          <span>Metode Tanam</span>
                          <span>Mulai Tanam</span>
                          <span>Tanggal Panen</span>
                        </div>
                        <div className="space-y-3 flex flex-col text-right md:text-left">
                          <span>{data?.product_name_var || "-"}</span>
                          <span>{data?.unique_code_var || "-"}</span>
                          <span>{data?.commodity_name_var || "-"}</span>
                          <span>{data?.name_planting_var || "-"}</span>
                          <span>
                            {dayjs(data?.start_planting_dtm).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                          <span>
                            {dayjs(data?.harvest_on_dte).format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="mt-5 md:mt-0 md:space-y-2.5 grid grid-cols-2 md:flex flex-col">
                        <span className="font-semibold text-lightcayn">
                          Nama Petani
                        </span>
                        <span className="text-right md:text-left">
                          {data?.fullname_var || "-"}
                        </span>
                        <span className="font-semibold text-lightcayn mt-3 md:pt-3">
                          Pendamping (FA)
                        </span>
                        <span className="text-right md:text-left mt-3">
                          {data?.field_assistant_name_var || "-"}
                        </span>
                      </div>
                    </div>
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QRProductDetail;
