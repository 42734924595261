import axios from "axios";
import { baseURL } from "./common-api";

export interface IDataPanen {
  id_seq?: number;
  commodity_name_var?: string;
  land_code_var?: string;
  period_plant_txt?: string;
  harvest_flo?: number;
  harvest_on_dte?: string;
  harvest_drying_flo?: number;
  harvest_drying_dte?: string;
  harvest_milling_flo?: number;
  harvest_milling_dte?: string;
  document_txt?: any;
  images?: any;
  status_int?: number;
  land_name_var?: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  user_id_int?: number;
  commodity_id?: number;
  variety_id?: number;
  variety_name?: string;
  fullname_var?: string;
}

export const getAllDataPanen = async () => {
  try {
    const data = await axios.get(baseURL + "/api/datapanen/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const getOneDataPanen = async (panenID: number) => {
  try {
    const data = await axios.get(
      baseURL + "/api/datapanen/get-one?id_seq=" + panenID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewDataPanen = async (payload: FormData) => {
  try {
    const data = await axios.post(baseURL + "/api/datapanen/create", payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateDataPanen = async (idSeq: number, payload: FormData) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/datapanen/update/" + idSeq,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteDataPanen = async (idSeq: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/datapanen/delete/" + idSeq,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export class DataPanenApprovalApi {
  static request = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(
        baseURL + "/api/datapanen/approval/" + idSeq,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static approval = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(
        baseURL + "/api/datapanen/approved/" + idSeq,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static reject = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(
        baseURL + "/api/datapanen/rejected/" + idSeq,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static draft = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(
        baseURL + "/api/datapanen/draft/" + idSeq,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getDropdownActivity = async () => {
    try {
      const data = await axios.get(
        baseURL + "/api/datapanen/dropdownActivity",
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };
}
