import L from "leaflet";
import {
  RouteAdminRole,
  RouteAnalisRole,
  RouteInvestorRole,
  RoutePetaniRole,
} from "../routes";
// import mapLogo from '../assets/img/maps-agroobot.png'
import dayjs from "dayjs";
import mapLogo from "../assets/img/logo.png";

const icon = new L.Icon({
  iconUrl: mapLogo,
  iconRetinaUrl: mapLogo,
  iconSize: new L.Point(30, 35),
});

export const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
export const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

const validateLatLng = (latLng) => {
  for (const item of latLng) {
    if (!isLatitude(item.lat) || !isLongitude(item.lng)) {
      return false;
    } else {
      return true;
    }
  }
};

class Helper {
  static getMainRoutes = (userGroupID) => {
    const id = parseInt(userGroupID);
    let routes = RoutePetaniRole;

    if (id === 1) routes = RouteAdminRole;
    else if (id === 2) routes = RouteAdminRole;
    else if (id === 3) routes = RouteAnalisRole;
    else if (id === 4) routes = RoutePetaniRole;
    else if (id === 5) routes = RouteInvestorRole;
    else routes = RoutePetaniRole;

    return routes;
  };

  static getUserRole = (userGroupID) => {
    try {
      const localRole = localStorage.getItem("pandawa-role")
        ? JSON.parse(localStorage.getItem("pandawa-role"))
        : [];
      if (localRole.length === 0) {
        return "";
      } else {
        return localRole.find(
          (data) => parseInt(data.id_seq) === parseInt(userGroupID)
        )?.group_name_var;
      }
    } catch (error) {
      // console.log(error)
      return "";
    }
  };

  static getRoleDropdown = (userGroupID) => {
    const id = parseInt(userGroupID);
    if (id === 1) {
      return [
        { id: 2, role: "Administrator" },
        { id: 3, role: "Analyst" },
        { id: 4, role: "Petani" },
        { id: 5, role: "Investor" },
      ];
    } else {
      return [
        { id: 3, role: "Analyst" },
        { id: 4, role: "Petani" },
        { id: 5, role: "Investor" },
      ];
    }
  };

  static validateForm = async (payload, allowEmpty) => {
    await Promise.all(
      Object.keys(payload).map(async (key) => {
        if (allowEmpty) {
          if (
            payload[key] === null ||
            payload[key] === undefined ||
            payload[key] === "Invalid date"
          ) {
            await delete payload[key];
          }
        } else {
          if (
            payload[key] === null ||
            payload[key] === undefined ||
            payload[key] === "Invalid date" ||
            payload[key] === ""
          ) {
            await delete payload[key];
          }
        }
        if (
          key === "allow_mobile_int" ||
          key === "allow_web_int" ||
          key === "created_by_var" ||
          key === "created_on_dtm" ||
          key === "id_seq" ||
          key === "last_login_on_dtm" ||
          key === "status_int" ||
          key === "updated_by_var" ||
          key === "updated_on_dtm" ||
          key === "user_group_id_int" ||
          key === "password_var" ||
          key === "iat" ||
          key === "exp"
        ) {
          await delete payload[key];
        }
      })
    );

    return payload;
  };

  static IDPhoneNumber = (phoneNumber) => {
    let msisdn = "";
    if (phoneNumber) {
      if (phoneNumber.charAt(0) === "0") {
        msisdn = "62" + phoneNumber.substring(1, phoneNumber.length);
      } else {
        msisdn = phoneNumber;
      }
    } else {
      msisdn = phoneNumber;
    }

    return msisdn;
  };

  static generatelatLngFromStr = (arrPolygon) => {
    try {
      const latLng = arrPolygon.split(";").map((koor) => {
        return { lat: koor.split(",")[0], lng: koor.split(",")[1] };
      });
      if (!validateLatLng(latLng)) {
        return [{ lat: 0, lng: 0 }];
      } else {
        return latLng;
      }
    } catch (error) {
      return [{ lat: 0, lng: 0 }];
    }
  };

  static getCenterMapFromStr = (arrPolygon) => {
    try {
      const latLng = arrPolygon.split(";").map((koor) => {
        return { lat: koor.split(",")[0], lng: koor.split(",")[1] };
      });
      if (!validateLatLng(latLng)) {
        return { lat: 0, lng: 0 };
      } else {
        return L.latLngBounds(latLng).getCenter();
      }
    } catch (error) {
      return { lat: 0, lng: 0 };
    }
  };

  static getMapIcon = () => {
    return icon;
  };

  static getMapURL = () =>
    "https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA";

  static isOldSampling = (datetime, dayCount) => {
    if (dayjs(datetime).add(dayCount, "days").isBefore(dayjs())) {
      return true;
    } else {
      return false;
    }
  };

  static getNeeds = (ref, real) => {
    const refData = parseFloat(ref);
    const realData = parseFloat(real);
    let needs = 10;

    if (refData - realData <= 10) {
      needs = 10;
    } else {
      needs = refData - realData;
    }

    return needs;
  };

  static getNeedsPH = (ref, real) => {
    const refData = parseFloat(ref);
    const realData = parseFloat(real);

    // if(refData - realData < 0){
    //     return 0
    // }else{
    //     return refData - realData
    // }
    return refData - realData;
  };

  static asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        return localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    },
    clear: function () {
      return Promise.resolve().then(function () {
        return localStorage.clear();
      });
    },
  };

  static capitalEachWord = (letter) => {
    try {
      var separateWord = letter.toLowerCase().split(" ");
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(" ");
    } catch (error) {
      return letter;
    }
  };

  static async getArrayController(obj) {
    try {
      const res = [];
      let lastKey = "";
      let lastValue = "";
      await Promise.all(
        Object.keys(obj).map((key) => {
          // eslint-disable-line
          if (key.includes("relay_")) {
            if (
              obj[key] !== null &&
              obj[key] !== "" &&
              obj[key] !== "null" &&
              obj[key] !== undefined
            ) {
              if (key.substring(0, 7) === lastKey) {
                let subObj = {
                  key: key.substring(0, 7),
                  name: lastValue,
                  status: obj[key],
                };
                res.push(subObj);
              }
              lastValue = obj[key];
              lastKey = key.substring(0, 7);
            }
          }
        })
      );

      return res;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  static amount = (bill) => {
    try {
      return bill
        .toString()
        .match(/\d{1,3}(?=(\d{3})*$)/g)
        .join(".");
    } catch (error) {
      return bill;
    }
  };

  static logout = () => {
    localStorage.removeItem("pandawa-user");
    localStorage.removeItem("pandawa-token");
  };
}

export const getTitleBar = (pathname) => {
  try {
    if (pathname === "/dashboard") return "Dashboard";
    else {
      const routes = localStorage.getItem("pandawa-menu")
        ? JSON.parse(localStorage.getItem("pandawa-menu"))
        : [];
      const array = [];
      routes.forEach((item) => {
        const obj = {
          name_var: item.name_var,
          url_var: item.url_var,
          icon_var: item.icon_var,
        };
        array.push(obj);
        if (item.children && item.children.length > 0) {
          item.children?.forEach((item_1) => {
            const obj_1 = {
              name_var: item_1["name_var"],
              url_var: item_1["url_var"],
              icon_var: item_1["icon_var"],
            };
            array.push(obj_1);
          });
        }
      });
      return array.find((data) => pathname === "/dashboard/" + data.url_var)
        ?.name_var;
    }
  } catch (error) {
    return "";
  }
};

export const getPayloadRecentActivity = (
  action,
  module,
  dataIdOrName,
  by,
  pathname
) => {
  let title, activity;

  switch (action) {
    case 1:
      title = `New ${module}`;
      activity = `Add new ${module.toLowerCase()} ${dataIdOrName} by ${by}`;
      break;
    case 2:
      title = `Modified ${module}`;
      activity = `Modified ${module.toLowerCase()} ${dataIdOrName} by ${by}`;
      break;
    case 3:
      title = `Remove ${module}`;
      activity = `Delete ${module.toLowerCase()} ${dataIdOrName} by ${by}`;
      break;
    default:
      break;
  }

  return {
    id: new Date().getTime(),
    title: title,
    activity: activity,
    path: pathname,
    url: window.location.href,
    is_read: false,
  };
};

export const getFilename = (file) => {
  try {
    return file.split(".").length > 0 ? file.split(".")[0] : file;
  } catch (error) {
    return file;
  }
};

export default Helper;
