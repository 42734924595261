import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalConfirm from '../modal/ConfirmModal';
import { Transition } from '@tailwindui/react';

export const DropdownActionUser = connect(
    state => ({
        user: state.user
    })
)(({ data, roleID, onDetail, onEdit, onDelete }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [confirmDelete, showConfirmDelete] = useState(false)

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleDelete = () => {
        showConfirmDelete(false)
        onDelete(data.id_seq)
    }

    return (
        <div className="relative inline-flex justify-center item-center">
            <button ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-5 block rounded-md focus:outline-none cursor-pointer" aria-expanded={dropdownOpen} aria-haspopup="true">
                <span className='text-gray-500 text-lg hover:text-lightcayn'><i className="fa-solid fa-ellipsis"></i></span>
            </button>

            {/* {dropdownOpen && */}
            <Transition show={dropdownOpen}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="origin-top-right z-10 absolute top-full right-0 w-max bg-white border border-gray-200 py-1.5 px-1 rounded shadow-lg overflow-hidden mt-1"
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="bg-white rounded-md shadow-xl z-auto">
                        {roleID === 4 &&
                            <span onClick={onDetail} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                                <i className="fa-solid fa-eye"></i>&nbsp; Lihat Detail
                            </span>
                        }
                        <span onClick={onEdit} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <i className="fa-solid fa-pen-to-square"></i>&nbsp; Ubah
                        </span>
                        <span onClick={() => showConfirmDelete(true)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <i className="fa-solid fa-trash-can"></i>&ensp; Hapus
                        </span>
                    </div>
                </div>
            </Transition>

            {confirmDelete && <ModalConfirm message='Delete this item?' onOK={handleDelete} onCancel={() => showConfirmDelete(false)} />}
        </div>
    )
})


export const DropdownLand = ({ data, onEdit, onDelete, onDetail = null }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [confirmDelete, showConfirmDelete] = useState(false)

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleEdit = () => {
        setDropdownOpen(false)
        onEdit()
    }

    const handleDelete = () => {
        showConfirmDelete(false)
        onDelete(data.id_seq)
    }

    const handleDetail = () => {
        setDropdownOpen(false)
        onDetail()
    }

    return (
        <div className="relative inline-flex justify-center item-center">
            <button ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-5 block rounded-md focus:outline-none cursor-pointer" aria-expanded={dropdownOpen} aria-haspopup="true">
                <span className='text-gray-500 text-lg hover:text-lightcayn'><i className="fa-solid fa-ellipsis"></i></span>
            </button>

            {/* {dropdownOpen && */}
            <Transition show={dropdownOpen}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="origin-top-right z-10 absolute top-full right-0 w-max bg-white border border-gray-200 py-1.5 px-1 rounded shadow-lg overflow-hidden mt-1"
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="bg-white rounded-md z-auto flex flex-col">
                        {
                            onDetail &&
                            <span onClick={handleDetail} className="text-sm py-2 pl-2 pr-12 capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer rounded-md">
                                <i className="fa-solid fa-eye"></i>&nbsp; Lihat Detail
                            </span>
                        }
                        {
                            onEdit &&
                            <span onClick={handleEdit} className="text-sm py-2 pl-2 pr-12 capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer rounded-md">
                                <i className="fa-solid fa-pen-to-square"></i>&nbsp; Ubah
                            </span>
                        }
                        {
                            onDelete &&
                            <span onClick={() => showConfirmDelete(true)} className="text-sm py-2 pl-2 pr-12 capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer rounded-md">
                                <i className="fa-solid fa-trash-can"></i>&ensp;&nbsp;Hapus
                            </span>
                        }
                    </div>
                </div>
            </Transition>

            {confirmDelete && <ModalConfirm onOK={handleDelete} onCancel={() => showConfirmDelete(false)} icon="fa-solid fa-trash-can" />}
        </div>
    )
}