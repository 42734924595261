import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import TableUser from "../../components/tables/Table";
import { CustomDropdown } from "../../components/dropdown/CustomDropdown";

import Loader from "../../components/modal/Loader";

import { getAllCommodity, ICommodity } from "../../api/commodity-api";
import dayjs from "dayjs";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalProvider";
import { IRecentPage } from "../../context/ContextInterface";

const TITLE_BAR = "Cultivation Management / Task Management";

type Props = {
  user?: any;
};

const TasksManagementTable: React.FC<Props> = ({ user }) => {
  const [loader, showLoader] = useState(false);
  const [modalForm, showModalForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [originData, setOriginData] = useState<ICommodity[] | []>([]);
  const [filterData, setFilterData] = useState<ICommodity[] | []>([]);
  const [selectedData, setSelectedData] = useState<ICommodity | null>(null);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await getAllCommodity();

    showLoader(false);
    console.log("Culti Management :", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, dispatch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return (
          item.fullname_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.land_name_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.land_code_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.commodity_name_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.variety_name
            ?.toLowerCase()
            ?.includes(event.target.value.toLowerCase()) ||
          item.commodity_type_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.start_planting_dtm
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.period_plant_txt
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });

      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Petani",
      accessor: "fullname_var",
    },
    {
      Header: "Lahan",
      accessor: "land_name_var",
    },
    {
      Header: "Komoditas",
      accessor: "commodity_name_var",
    },
    {
      Header: "Varietas",
      accessor: "variety_name",
    },
    {
      Header: "Tipe",
      accessor: "commodity_type_var",
    },
    {
      Header: "Mulai Tanam",
      accessor: "start_planting_dtm",
      Cell: ({ value }: any) => dayjs(value).format("YYYY-MM-DD"),
    },
    {
      Header: "Periode Tanam",
      accessor: "period_plant_txt",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }: any) =>
        parseInt(value) === 1 ? (
          <span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
            Active
          </span>
        ) : (
          <span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
            Inactive
          </span>
        ),
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        if (user?.user_group_id_int === 1 || user?.user_group_id_int === 2) {
          return (
            <CustomDropdown data={data}>
              <span
                onClick={() => alert(data.id_seq)}
                className="text-sm py-2 pl-2 pr-12 capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer rounded-md">
                <i className="fa-solid fa-eye"></i>&nbsp; Lihat Task
              </span>
            </CustomDropdown>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
      <div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
        <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
          <h1 className="text-xl font-bold uppercase">List Task Management</h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField
              onChange={handleSearch}
              placeholder="Cari data cultivation..."
            />
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(TasksManagementTable);
