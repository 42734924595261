import { useCallback, useContext, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"

import TableUser from "../../components/tables/Table"
import { ButtonAdd } from "../../components/button/CustomButton"

import Loader from "../../components/modal/Loader";
import { toast } from "react-toastify"

import React from 'react';
import { createNewUserGroup, deleteUserGroup, getAllUserGroup, IUserGroup, updateUserGroup } from "../../api/usergroup-api"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"
import ModalUserGroup from "../../components/modal/ModalUserGroup"
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { GlobalContext } from "../../context/GlobalProvider";
import { useLocation } from "react-router-dom";
import { getPayloadRecentActivity } from "../../helpers/helper";
import { IRecentPage } from "../../context/ContextInterface";


const TITLE_BAR = 'User Management / User Group'

type Props = {
    user?: any,
    titleBar?: string
};

const UserGroup: React.FC<Props> = ({ user, titleBar }) => {
    const [loader, showLoader] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [originData, setOriginData] = useState<IUserGroup[]|[]>([])
    const [filterData, setFilterData] = useState<IUserGroup[]|[]>([])
    const [selectedData, setSelectedData] = useState<IUserGroup|null>(null)

    const dispatch = useDispatch()
    const { dispatchCalActivity, dispatchCalPage } = useContext(GlobalContext)
    const {pathname} = useLocation();

    useEffect(() => {
        const payload: IRecentPage = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };
        
        dispatchCalPage({type: 'push', payload})
        dispatch({type: ActionType.SET_TITLE_BAR, value: TITLE_BAR})
    }, [dispatch, dispatchCalPage, pathname])


    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await getAllUserGroup(localStorage.getItem('pandawa-token') || '')

        showLoader(false)
        console.log("User group :", res)
        if(res.data){
            if(res.data.code === 0){
                setOriginData(res.data.data)
                setFilterData(res.data.data)
				localStorage.setItem('pandawa-role', JSON.stringify(res.data.data))
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    const handleEditData =  (selectedData: IUserGroup) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (data: IUserGroup) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            data.created_by_var = user.fullname_var
            res = await createNewUserGroup(data)
            
            var payload = getPayloadRecentActivity(1, 'User Group', data.group_name_var, user.fullname_var, pathname)
        }else{
            data.updated_by_var = user.fullname_var
            res = await updateUserGroup(selectedData?.id_seq!, data)
            
            payload = getPayloadRecentActivity(2, 'User Group', data.group_name_var, user.fullname_var, pathname)
        }

        console.log('Create/Update UserGroup :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)
                
                dispatchCalActivity({type: 'push', payload})

                fetchData()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const handleDeleteData = async (data: IUserGroup) => {
        showLoader(true)
        const res = await deleteUserGroup(data.id_seq!)

        console.log("DELETE UserGroup :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)

                const payload = getPayloadRecentActivity(3, 'User Group', data.group_name_var, user.fullname_var, pathname)
                dispatchCalActivity({type: 'push', payload})
                
                fetchData()
                resetForm()
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const resetForm = () => {
        setSelectedData(null)
        setIsUpdate(false)
        showModalForm(false)
        showLoader(false)
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) =>  <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'Group ID',
            accessor: 'id_seq',
        },
        {
            Header: 'Nama Group',
            accessor: 'group_name_var',
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}: any) => (
                parseInt(value)===1? 
                <span className='bg-green-100 text-green-800 px-2 py-1 rounded-lg font-semibold'>Active</span>
                :
                <span className='bg-red-100 text-red-800 px-2 py-1 rounded-lg font-semibold'>Inactive</span>
            )
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return <DropdownLand
                        data={data}
                        onEdit={() => handleEditData(data)}
                        onDelete={()=>handleDeleteData(data)}
                    />
            }
        }
    ]

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const newData = [...originData]
        if(event.target.value){
            console.log(event.target.value)
            const filtered = newData.filter(item => {
                return (
                    item.group_name_var.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(originData)
        }
    }



    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>List User Group</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari user group...' />
                        <ButtonAdd onClick={() => showModalForm(true)} />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalUserGroup 
                data={selectedData}
                onClose={resetForm}
                onSubmit={handleReceiveDataForm}
            />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        titleBar: state.title_bar
    }
}

export default connect(mapStateToProps, null)(UserGroup)