import React from 'react';
import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import Helper from "../../helpers/helper"
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { getUserGroupDropdown } from "../../api/user-api";
import { IUserGroup } from "../../api/usergroup-api";
import Loader from './Loader';
import { IFarmerGroup } from '../../api/farmergroup-api';


type Props = {
    user?: any,
    title?: string,
    data: any,
    farmerGroupList: IFarmerGroup[],
    onCancel: (e: React.MouseEvent) => any,
    onSubmit: (data: any) => any
};

const ModalFormUser: React.FC<Props> = ({ user, title, data, farmerGroupList, onCancel, onSubmit }) => {
    const [errPass, showErrPas] = useState(false)
    const [loader, showLoader] = useState(true)
    const localRole = localStorage.getItem('pandawa-role') ? JSON.parse(localStorage.getItem('pandawa-role') || '') : []
    const [userGroup, setUserGroup] = useState<IUserGroup[]>(localRole)
    const [userGroupID, setUserGroupID] = useState(99)

    useEffect(() => {
        (async function fetchUserGroup() {
            const res = await getUserGroupDropdown(user.user_group_id_int)
            console.log("fetching dropdown role :", res)

            if (res.data) {
                if (res.data.code === 0) {
                    // setUserGroup(res.data.data.sort((a: any,b: any) => b.id_seq - a.id_seq))
                    setUserGroup(res.data.data)
                    showLoader(false)
                }
            }
        })()

    }, [user.user_group_id_int])

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setUserGroupID(data.user_group_id_int)
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    const onValid = (dataForm: any) => {
        const { fullname_var, user_group_id_int, phone_number_int, email_var, password_var, confirm_pass, farmer_group_id_int } = dataForm

        /* Password Feld hanya muncul ketika add user dan user role selain petani */
        if (!data) {
            if (password_var !== confirm_pass) {
                showErrPas(true)
                return
            }
        }

        const payload = {
            fullname_var: Helper.capitalEachWord(fullname_var),
            user_group_id_int,
            farmer_group_id_int: parseInt(user_group_id_int) === 4 ? farmer_group_id_int || 99 : 99,
            phone_number_int: Helper.IDPhoneNumber(phone_number_int),
            password_var: password_var || phone_number_int,
            email_var: email_var || ''
        }

        if (data) delete payload.password_var

        onSubmit(payload)
    }

    return (
        <>
            {!loader ?
                <div className="modal-form">
                    <div className="modal-form-outside" onClick={onCancel}></div>

                    <div className="modal-form-content" style={{ maxHeight: '90vh' }}>
                        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                                <i className="fa-solid fa-user flex justify-center items-center"></i>
                                <h3>{data ? 'Ubah User' : 'Tambah User'}</h3>
                            </div>
                            <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                                onClick={onCancel}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onValid)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8 space-y-5">
                            <div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
                                <div className='w-full md:w-1/2'>
                                    <label className='label-form'>Nama Lengkap</label>
                                    <input type='text' className='input-form' placeholder='Nama Lengkap'
                                        {...register("fullname_var", {
                                            required: "Fullname is required."
                                        })}
                                    />
                                    <ErrorField errors={errors} name='fullname_var' />
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <label className='label-form'>Role</label>
                                    <select id='routeUser' className='input-form' {...register("user_group_id_int", { required: "Role is required." })} onChange={(e) => setUserGroupID(parseInt(e.target.value))}>
                                        <option value='99'>- Pilih Role -</option>
                                        {userGroup?.map(({ id_seq, group_name_var }: IUserGroup, key: number) =>
                                            <option key={key} value={id_seq} >[{id_seq}] {group_name_var}</option>
                                        )}
                                    </select>
                                    <ErrorField errors={errors} name='user_group_id_int' />
                                </div>
                            </div>
                            <div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
                                <div className='w-full md:w-1/2'>
                                    <label className='label-form'>No. Handphone</label>
                                    <input type='tel' className='input-form' placeholder='081234567890'
                                        {...register("phone_number_int", {
                                            required: "Phone number is required.",
                                            pattern: { value: /^\d+$/, message: "Phone number is number only." },
                                            minLength: { value: 10, message: "Phone number must exceed 9 characters." },
                                            maxLength: { value: 14, message: "Phone number too long." }
                                        })}
                                    />
                                    <ErrorField errors={errors} name='phone_number_int' />
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <label className='label-form'>Email</label>
                                    <input type='email' className='input-form' placeholder='user@mail.com'
                                        {...register("email_var", {
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address."
                                            }
                                        })}
                                    />
                                    <ErrorField errors={errors} name='email_var' />
                                </div>
                            </div>

                            {/* Password hanya muncul ketika add user dan user role selain petani */}
                            {!data &&
                                <div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
                                    <div className='w-full md:w-1/2'>
                                        <label className='label-form'>Password</label>
                                        <input type='password' className='input-form' placeholder='*****'
                                            {...register("password_var", {
                                                required: "Password is required.",
                                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                                            })}
                                        />
                                        <ErrorField errors={errors} name='password_var' />
                                    </div>
                                    <div className='w-full md:w-1/2'>
                                        <label className='label-form'>Konfirmasi Password</label>
                                        <input type='password' className='input-form' placeholder='*****'
                                            {...register("confirm_pass", { required: "Confirm password is required." })}
                                        />
                                        <ErrorField errors={errors} name='confirm_pass' />
                                    </div>
                                </div>
                            }

                            {errPass && <p className='text-red-500 mt-3 mb-3 font-medium'>* Password not match</p>}


                            {/* Farmer Group hanya muncul ketika user role nya hanya Farmer */}
                            {userGroupID === 4 &&
                                <div className='md:flex md:space-x-10 space-y-5 md:space-y-0 mr-10'>
                                    <div className='w-full md:w-1/2'>
                                        <label className='label-form'>Farmer Group</label>
                                        <select id='farmerGroup' className='input-form' {...register("farmer_group_id_int", { required: "Farmer Group is required." })}>
                                            <option value=''>- Pilih Farmer Group -</option>
                                            {farmerGroupList.map(({ id_seq, farmer_group_name_var }, key: number) =>
                                                <option key={key} value={id_seq} >[{id_seq}] {farmer_group_name_var}</option>
                                            )}
                                        </select>
                                        <ErrorField errors={errors} name='farmer_group_id_int' />
                                    </div>
                                </div>
                            }

                            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                                <button type='submit' className="btn-primary">Simpan</button>
                                <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                            </div>

                        </form>

                    </div>

                </div>
                :
                <Loader />
            }
        </>
    )
}


const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(ModalFormUser)