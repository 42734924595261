import dayjs from "dayjs";
import html2canvas from "html2canvas";
import QrCodeWithLogo from "qr-code-with-logo";
import { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { toast } from "react-toastify";
import { IExitProduct, IMixingProduct } from "../../../api/inventory-api";
import ParameterApi from "../../../api/parameter-api";
import logo from "../../../assets/img/logo.png";
import Loader from "../Loader";

type Props = {
  isCombination?: boolean;
  data: IExitProduct | IMixingProduct;
  onCancel: (e?: React.MouseEvent) => any;
};

const ModalQRProduct: React.FC<Props> = ({ isCombination, data, onCancel }) => {
  const [loader, showLoader] = useState(true);
  const [qrUrl, setQRUrl] = useState("");

  useEffect(() => {
    (async function getQRUrl() {
      showLoader(true);

      const paramsCode =
        isCombination || data.product_type_int === 3 ? "BUC" : "BU";
      const res = await ParameterApi.getOne(paramsCode);

      console.log("GET QR URL ==>", res);
      if (res.data && res.data.code === 0) {
        setQRUrl(res.data.data.operating_parameters_value_var);
        showLoader(false);
      } else {
        toast.error(
          res.data?.message ||
            res.status + " " + res.message ||
            "Connection timeout"
        );
        onCancel();
      }
    })();
    // eslint-disable-next-line
  }, [isCombination]);

  const handleDownloadQR = () => {
    showLoader(true);
    const baseOption = {
      content:
        isCombination || data.product_type_int === 3
          ? `${qrUrl}?code=${
              (data as IMixingProduct)?.qr_code_var ||
              (data as IExitProduct)?.unique_code_var
            }&iscombination=true`
          : `${qrUrl}?code=${
              (data as IExitProduct)?.unique_code_var
            }&iscombination=false`,
      width: 380,
      nodeQrCodeOptions: {
        margin: 1,
        color: {
          dark: "#152e4d",
          light: "#ffffff",
        },
      },
      logo: {
        src: logo,
        crossOrigin: "Anonymous",
        bgColor: "#152e4d",
      },
      download: true,
      downloadName:
        isCombination || data.product_type_int === 3
          ? (data as IMixingProduct)?.qr_code_var ||
            (data as IExitProduct)?.unique_code_var + ".png"
          : data.id_product_var +
            "-" +
            (data as IExitProduct)?.unique_code_var +
            ".png",
    };

    QrCodeWithLogo.toImage(baseOption)
      .then((res) => {
        toast.info("QR Code successfully downloaded");
        showLoader(false);
      })
      .catch((err) => {
        toast.error("Oops..something error", err);
        showLoader(false);
      });
  };

  const handlePrintQR = () => {
    showLoader(true);

    var printWindow: any = window.open("", "", "height=500, width=500");

    html2canvas(document.querySelector("#printQR")!, {
      scale: 1,
      logging: true,
      allowTaint: false,
      useCORS: true,
    })
      .then(function (canvas: any) {
        var doc = printWindow.document;
        var img = doc.createElement("img");
        img.src = canvas.toDataURL("image/png");
        img.style =
          "border: 3px dashed #aaa; border-radius: 20px; padding: 3px 5px; margin-right: 2px";
        doc.body.appendChild(img);

        setTimeout(() => {
          printWindow.print();
        }, 100);
        setTimeout(() => {
          printWindow.close();
          showLoader(false);
          toast.success("QR Code successfully printed");
        }, 200);
      })
      .catch((err) => {
        toast.error("Oops..something error", err);
        showLoader(false);
      });
  };

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-outside" onClick={onCancel}></div>

        <div
          className={`modal-form-content ${
            isCombination || data.product_type_int === 3
              ? "w-[95%] md:w-max 2xl:w-6/12"
              : ""
          } `}
          style={{ maxHeight: "90vh" }}
        >
          <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300">
            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
              <h3>
                {isCombination
                  ? "QR Code Pencampuran Produk"
                  : "QR Code Barang Keluar"}
              </h3>
            </div>
            <button
              type="button"
              className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              onClick={onCancel}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          {isCombination || data.product_type_int === 3 ? (
            <div
              className="flex flex-col md:flex-row items-center md:items-start py-5 px-0 lg:p-8"
              id="printQR"
            >
              <QRCode
                value={`${qrUrl}?code=${
                  (data as IMixingProduct)?.qr_code_var ||
                  (data as IExitProduct)?.unique_code_var
                }&iscombination=true`}
                ecLevel="M"
                enableCORS={true}
                bgColor="#fff"
                fgColor="#152e4d"
                size={180}
                quietZone={10}
                qrStyle="squares"
              />
              <div className="grid grid-cols-3 md:gap-x-3 w-full text-sm md:text-xl ml-5 mt-5 md:mt-3 font-medium">
                <div className="flex flex-col text-gray-500 space-y-3 md:space-y-5">
                  <span>QR Code</span>
                  <span>Nama Produk</span>
                  <span>Berat Bersih</span>
                </div>
                <div className="col-span-2 flex flex-col space-y-3 md:space-y-5">
                  <span>
                    {(data as IMixingProduct)?.qr_code_var ||
                      (data as IExitProduct)?.unique_code_var ||
                      "-"}
                  </span>
                  <span>{data.product_name_var}</span>
                  <span>
                    {data.quantity_int} {data.measure_var}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col md:flex-row items-center md:items-start p-5 lg:p-8"
              id="printQR"
            >
              <QRCode
                value={`${qrUrl}?code=${
                  (data as IExitProduct)?.unique_code_var
                }&iscombination=false`}
                ecLevel="M"
                enableCORS={true}
                bgColor="#fff"
                fgColor="#152e4d"
                size={180}
                quietZone={10}
                qrStyle="squares"
              />
              <div className="grid grid-cols-3 w-full text-sm md:text-xl ml-5 mt-5 md:mt-3 font-medium">
                <div className="flex flex-col text-gray-500 space-y-3 md:space-y-5">
                  <span>QR Code</span>
                  <span>Nama Produk</span>
                  <span>Tgl. Keluar</span>
                </div>
                <div className="col-span-2 flex flex-col space-y-3 md:space-y-5">
                  <span>{(data as IExitProduct)?.unique_code_var || "-"}</span>
                  <span>{data.product_name_var}</span>
                  <span>
                    {dayjs((data as IExitProduct).time_on_dte).format(
                      "DD MMMM YYYY"
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          <footer className="bg-gray-100 flex justify-end space-x-5 p-5 lg:p-8 lg:py-5">
            <button
              className="bg-green-500 rounded-full text-sm text-white py-2.5 px-7 flex items-center hover:opacity-60 ease-in-out duration-300"
              onClick={handlePrintQR}
            >
              <i className="fa-solid fa-print mr-2"></i>
              <span>Print</span>
            </button>
            <button
              className="bg-blue-500 rounded-full text-sm text-white py-2.5 px-5 flex items-center hover:opacity-60 ease-in-out duration-300"
              onClick={handleDownloadQR}
            >
              <i className="fa-solid fa-download mr-2"></i>
              <span>Download</span>
            </button>
          </footer>
        </div>
      </div>

      {loader && <Loader />}
    </>
  );
};

export default ModalQRProduct;
