import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IProjectManagement } from "@api/project-api";
import FarmerGroupApi, { IFarmerGroup } from "@api/farmergroup-api";
import TableUser from "@components/tables/Table";
import SearchField from "@components/SearchField";

type Props = {
  data: IFarmerGroup;
  projectList: IProjectManagement[];
  onCancel: (e: React.MouseEvent) => any;
};

const ModalDetailFarmerGroup: React.FC<Props> = ({ data, onCancel }) => {
  // hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  // states
  const [farmerData, setFarmerData] = useState([]);

  // functions
  const fetchFarmers = async () => {
    const query = `farmer_group_id_int=${data?.id_seq}`;
    const res = await FarmerGroupApi.getFarmers(query);
    console.log({ res });
    setFarmerData(res.data.data);
  };

  // effects
  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );
  useEffect(() => {
    if (data) {
      setdefaultValue(data);
      fetchFarmers();
    }
  }, [data, setdefaultValue]);

  // renders
  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }) => <div className="pl-4 text-left">{row.index + 1}.</div>,
    },
    {
      Header: "Name",
      accessor: "fullname_var",
    },
    {
      Header: "Phone Number",
      Footer: "Phone Number",
      accessor: "phone_number_int",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email_var",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }) =>
        parseInt(value) === 1 ? (
          <span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
            Active
          </span>
        ) : (
          <span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
            Inactive
          </span>
        ),
    },
  ];

  return (
    <div className="modal-form">
      <div className="modal-form-outside" onClick={onCancel}></div>

      <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
          <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
            <i className="fa-solid fa-people-group flex justify-center items-center"></i>
            <h3>Detail Farmer Group - {data?.farmer_group_name_var}</h3>
          </div>
          <button
            type="button"
            className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
            onClick={onCancel}>
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          </button>
        </div>

        <form className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
          <div className="space-y-5">
            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
              <div className="w-full">
                <label htmlFor="farmer_group_name_var" className="label-form">
                  Nama Group Tani
                </label>
                <input
                  id="farmer_group_name_var"
                  type="text"
                  className="input-form"
                  disabled
                  {...register("farmer_group_name_var", {
                    required: "Farmer Group Name is required.",
                    minLength: {
                      value: 2,
                      message: "Farmer Group Name length at least 2 characters",
                    },
                  })}
                />
              </div>
              <div className="w-full">
                <label htmlFor="project_code_var" className="label-form">
                  Projek
                </label>
                <input
                  id="farmer_group_name_var"
                  type="text"
                  className="input-form"
                  disabled
                  {...register("project_code_var")}
                />
              </div>
              <div className="w-full">
                <label htmlFor="project_code_var" className="label-form">
                  Jumlah Anggota
                </label>
                <input
                  id="farmer_group_name_var"
                  type="text"
                  className="input-form"
                  disabled
                  {...register("count_user")}
                />
              </div>
            </div>
          </div>

          <div className="w-full border mt-5 bg-white rounded-2xl md:p-6">
            <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
              <h1 className="text-xl font-bold uppercase">List Petani</h1>
            </div>
            <TableUser dataTable={farmerData} columnTable={columns} />
          </div>

          {/* <!-- Modal footer --> */}
          <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
            <button
              type="reset"
              className="btn-secondary px-7"
              onClick={onCancel}>
              Tutup
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalDetailFarmerGroup;
