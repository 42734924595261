import axios from "axios";
import { baseURL } from "./common-api";

export interface IPlantingMethod {
  id_seq?: number;
  name_planting_var: string;
  commodity_name_var: string;
  status_int: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const getAllPlantingMethod = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/task-reference/planting-method/get-all",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewPlantingMethod = async (payload: IPlantingMethod) => {
  try {
    const data = await axios.post(
      baseURL + "/api/task-reference/planting-method/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updatePlantingMethod = async (
  idSeq: number,
  payload: IPlantingMethod,
) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/task-reference/planting-method/update/" + idSeq,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deletePlantingMethod = async (idSeq: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/task-reference/planting-method/delete/" + idSeq,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
