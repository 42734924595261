import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import farmerIcon from "../../assets/img/farmer.png";

import { ApexOptions } from "apexcharts";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getActvitiesDashboard, getDashboard } from "../../api/common-api";
import Loader from "../../components/modal/Loader";
import TableMax from "../../components/tables/TableMax";
import { IRecentPage } from "../../context/ContextInterface";
import { GlobalContext } from "../../context/GlobalProvider";
import Helper from "../../helpers/helper";
import useDropdownFarmer from "../../hooks/useDropdownFarmer";
import useDropdownLand, {
  useDropdownPeriod,
} from "../../hooks/useDropdownLand";
import useMobileView from "../../hooks/useMobileView";
import { ActionType } from "../../reduxs/action/actions";
import { SelectCustomStyles } from "../admin/TaskManagement";

const TITLE_BAR = "Dashboard";

type Props = {};

const DashbboardAdmin: React.FC<Props> = () => {
  const [data, setData] = useState<any>(null);
  const [loader, showLoader] = useState(false);
  const [activities, setActivities] = useState<any[]>([]);
  const [selectedLand, setSelectedLand] = useState<any>(null);
  const [selectedFarmer, setSelectedFarmer] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
  const [temperature, setTemperature] = useState<any>(0);
  const [address, setAddress] = useState<string>("");

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isMobile } = useMobileView();
  const { userStorage, dispatchCalPage } = useContext(GlobalContext);

  const { dataFarmer, loadingFarmer } = useDropdownFarmer();
  const { dataLand, loadingLand } = useDropdownLand(
    selectedFarmer?.id_seq || 0,
  );
  const { dataPeriod, loadingPeriod } = useDropdownPeriod(
    selectedLand?.land_code_var || "",
  );

  var optionsPieChar: ApexOptions = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: { show: false },
          size: "60%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["RF", "Non RF"],
    // colors: ["#436B94", "#556ee6", "#cad1e0"],
    colors: ["#556ee6", "#436B94"],
    legend: { show: !1 },
    stroke: {
      width: 0,
    },
  };

  var optionsFunding: ApexOptions = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: { show: false },
          size: "60%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Realisasi Pendanaan", "Sisa Pendanaan"],
    colors: ["#436B94", "#556ee6"],
    // colors: ["#436B94", '#cad1e0'],
    legend: { show: !1 },
    stroke: {
      width: 0,
    },
  };

  var optionLand: ApexOptions = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          // labels: { show: false },
          size: "60%",
        },
      },
    },
    labels: ["Lahan Aktif", "Lahan Non Aktif"],
    dataLabels: {
      enabled: true,
      // formatter: function (val) {
      //     return val + "%"
      // },
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    legend: {
      position: "bottom",
    },
  };

  var optionLuas: ApexOptions = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          // labels: { show: false },
          size: "60%",
        },
      },
    },
    labels: ["Luas Aktif", "Luas Non Aktif"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Number(opts.w.config.series[opts.seriesIndex]).toFixed(2);
      },
    },
    legend: {
      position: "bottom",
    },
  };

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  useEffect(() => {
    (async function fetchDataDashboard() {
      showLoader(true);
      const res = await getDashboard();

      console.log("DASHBOARD ==> ", res);
      if (res.data && res.data.code === 0) {
        setData(res.data.data[0]);
        showLoader(false);
      } else {
        showLoader(false);
        toast.error(
          res.data?.message ||
            res.status + " " + res.message ||
            "Connection timeout",
        );
      }
    })();
  }, []);

  const fetchActivities = async () => {
    (async function fetchDataDashboard() {
      showLoader(true);
      const res = await getActvitiesDashboard(
        selectedFarmer?.id_seq || "ALL",
        selectedLand?.land_code_var || "ALL",
        selectedPeriod?.period_plant_txt || "ALL",
      );

      console.log("DASHBOARD ACTIVITIES ==> ", res);
      if (res.data && res.data.code === 0) {
        setActivities(res.data.data);
        showLoader(false);
      } else {
        showLoader(false);
        // toast.error(res.data?.message || res.status + ' ' + res.message || 'Connection timeout')
      }
    })();
  };
  const fetchGeo = async () => {
    const apiKey = "cb8ab884cf3548ef9fc28a39e802f067";
    const url = `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`; // https://ipgeolocation.io/

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        fetchTemperature(position.coords.latitude, position.coords.longitude);
      });
    } else {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          fetchTemperature(data.latitude, data.longitude);
        })
        .catch((error) => {
          console.error("ipgeo, Terjadi kesalahan:", error);
        });
    }
  };
  const fetchTemperature = async (lat, lng) => {
    const openWeatherApiKey = "b9d885f36f529de531fffc0c5a789512";
    const openWeatherUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${openWeatherApiKey}`;

    fetch(openWeatherUrl)
      .then((response) => response.json())
      .then((data) => {
        const temperatureKelvin = data.main.temp || 0;
        const temperatureCelcius = temperatureKelvin - 273.15;
        setTemperature(Math.round(temperatureCelcius));
        setAddress(data.name + ", " + data.sys.country);
      })
      .catch((error) => {
        console.error("ipgeo, Terjadi kesalahan:", error);
      });
  };

  useEffect(() => {
    fetchActivities();
    fetchGeo();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
      width: 10,
    },
    {
      Header: "Aktivitas",
      accessor: "activity_txt",
      width: 300,
    },
    {
      Header: "Waktu",
      accessor: "time_txt",
      width: 60,
    },
    {
      Header: "Tanggal",
      accessor: "time_calender_dte",
      width: 90,
      Cell: ({ value }: any) => {
        return dayjs(value).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Komoditas",
      accessor: "commodity_name",
      width: 100,
    },
    {
      Header: "Metode Tanam",
      accessor: "name_planting",
      width: 110,
    },
    {
      Header: "Lahan",
      accessor: "land_code_var",
      width: 125,
      Cell: ({ value, row }: any) => {
        const data = row.original;
        return (
          <span>
            [{value}] {data.land_name_var}
          </span>
        );
      },
    },
    {
      Header: "Periode",
      accessor: "period_plant_txt",
      width: 50,
    },
    {
      Header: "Petani",
      accessor: "fullname_var",
      width: 150,
    },
    {
      Header: "Pendamping",
      accessor: "field_assistant_name_var",
      width: 125,
    },
  ];

  return (
    <>
      <div className="flex flex-col space-y-5 text-sm font-medium md:flex-row md:space-y-0">
        <div className="w-full p-5 space-y-5">
          {/* HEADER */}
          <div className="w-full relative py-6 pl-[6.5rem] md:pl-40 bg-white rounded-2xl shadow-lg mt-2 flex flex-col justify-center">
            <h1 className="mb-1 text-base font-bold md:text-2xl">
              Hallo, {userStorage.fullname_var}..
            </h1>
            <p>Selamat datang di Dashboard Farm Management System</p>
            {isMobile ? (
              <LazyLoadImage
                className="absolute w-20 top-3 left-3"
                effect="black-and-white"
                alt=""
                src={farmerIcon}
              />
            ) : (
              <LazyLoadImage
                className="absolute bottom-0 left-6 w-28"
                effect="black-and-white"
                alt=""
                src={farmerIcon}
              />
            )}
          </div>

          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
            <Card
              title="Total Project"
              value={data?.total_project_int || "0"}
              color="blue"
            />
            <Card
              title="Total Petani"
              value={data?.total_farmer_int || "0"}
              color="green"
              icon="fa-solid fa-person-digging"
            />
            <Card
              title="Total FA"
              value={data?.total_fa_int || "0"}
              color="yellow"
              icon="fa-solid fa-user-group"
            />
          </div>

          <div className="flex flex-col-reverse space-y-5 md:flex-row md:space-x-5 md:space-y-0">
            <div className="flex flex-col w-full p-6 bg-white shadow-lg md:w-1/3 rounded-2xl md:p-5 2xl:p-7">
              <h3 className="mb-4 text-base font-bold">Detail Pendapatan</h3>
              <div className="flex pb-4 border-b">
                <i className="w-6 mr-4 text-xl text-gray-400 fa-solid fa-money-bill-wave 2xl:w-7 2xl:mr-5"></i>
                <div>
                  <p className="text-gray-500">Total Pendapatan</p>
                  <p className="text-2xl font-bold text-green-700 2xl:text-3xl">
                    Rp{Helper.amount(data?.total_gross_revenue_int)}
                  </p>
                </div>
              </div>
              <div className="flex mt-5">
                <i className="w-6 mr-4 text-xl text-gray-400 fa-solid fa-leaf 2xl:w-8 2xl:mr-5"></i>
                <div>
                  <p>Beras Campuran</p>
                  <p className="text-base font-bold 2xl:text-xl">
                    Rp{Helper.amount(data?.total_gross_beras_campuran_int)}
                  </p>
                </div>
              </div>
              <div className="flex mt-4 2xl:mt-5">
                <i className="w-6 mr-4 text-xl text-gray-400 fa-brands fa-pagelines 2xl:w-8 2xl:mr-5"></i>
                <div>
                  <p>Beras</p>
                  <p className="text-base font-bold 2xl:text-xl">
                    Rp{Helper.amount(data?.total_gross_beras_int)}
                  </p>
                </div>
              </div>
              <div className="flex mt-4 2xl:mt-5">
                <i className="w-6 mr-4 text-xl text-gray-400 fa-brands fa-envira 2xl:w-8 2xl:mr-5"></i>
                <div>
                  <p>Saprodi</p>
                  <p className="text-base font-bold 2xl:text-xl">
                    Rp{Helper.amount(data?.total_gross_saprodi_int)}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-2/3 pb-7 md:pb-0">
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                <Card
                  title="Total Stock Beras"
                  value={`${data?.stock_beras_int || "0"} Kg`}
                  color="slate"
                  icon="fa-brands fa-pagelines"
                  addingStyleValue="text-lg"
                  addingStyleTitle="text-xs"
                />
                <Card
                  title="Total Panen"
                  value={`${data?.total_panen_flo || "0"} Kg`}
                  color="red"
                  icon="fa-solid fa-leaf"
                  addingStyleValue="text-lg"
                />
              </div>

              <div className="mt-5 2xl:mt-7">
                <h3 className="text-lg font-semibold md:text-base 2xl:text-lg">
                  Data Lahan
                </h3>
                <div className="grid grid-cols-1 gap-5 mt-2 md:grid-cols-2 2xl:mt-4">
                  <div className="p-1 bg-white shadow-lg rounded-2xl">
                    <ReactApexChart
                      options={optionLand}
                      series={[
                        data?.total_land_active_int || 0,
                        data?.total_land_int - data?.total_land_active_int,
                      ]}
                      type="donut"
                    />
                    <div className="flex items-center justify-between py-3 text-xs md:py-1 2xl:py-3 px-14 md:px-4 2xl:px-14">
                      <div className="flex items-center">
                        <span className="w-3.5 h-3.5 rounded-full bg-[#FEB019] mr-3"></span>
                        <p>Total Keseluruhan</p>
                      </div>
                      <p className="font-bold">{data?.total_land_int}</p>
                    </div>
                  </div>
                  <div className="p-1 bg-white shadow-lg rounded-2xl">
                    <ReactApexChart
                      options={optionLuas}
                      series={[
                        data?.total_area_active_flo || 0,
                        data?.total_area_flo - data?.total_area_active_flo,
                      ]}
                      type="donut"
                    />
                    <div className="flex items-center justify-between py-3 text-xs md:py-1 2xl:py-3 px-14 md:px-4 2xl:px-14">
                      <div className="flex items-center">
                        <span className="w-3.5 h-3.5 rounded-full bg-[#FEB019] mr-3"></span>
                        <p>Total Keseluruhan</p>
                      </div>
                      <p className="font-bold">{data?.total_area_flo}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className="w-full md:w-[19.5rem] 2xl:w-96 bg-white shadow-lg rounded-2xl">
          <div className="flex justify-center p-5 bg-darkcayn item-center">
            <i className="flex items-center justify-center text-4xl text-yellow-300 fa-solid fa-cloud-sun"></i>
            <div className="ml-6 text-white md:ml-3 2xl:ml-5">
              <p className="text-4xl font-bold md:text-3xl 2xl:text-4xl">
                {temperature}&deg;C
              </p>
              <p className="text-sm md:text-[11.1px] 2xl:text-sm">
                {address && (
                  <>
                    {address}
                    <br />
                  </>
                )}
                {dayjs().format("dddd, DD MMMM YYYY")}
              </p>
            </div>
          </div>
          <div className="p-5">
            <div>
              <h3 className="text-base font-bold">Sumber Dana</h3>
              <ReactApexChart
                options={optionsPieChar}
                series={[data?.total_rf_int || 0, data?.total_nonrf_int || 0]}
                type="donut"
              />
              <div className="space-y-1.5 mb-6">
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-4 h-4 mr-3 bg-blue-500 rounded-full"></span>
                    <p>RF</p>
                  </div>
                  {/* <p className='font-bold'>({data?.total_rf_int}) {(data?.total_rf_int * 100 / (data?.total_rf_int + data?.total_nonrf_int)).toFixed(0)}%</p> */}
                  <p className="text-sm font-bold">{data?.total_rf_int}</p>
                </div>
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-4 h-4 rounded-full bg-[#436B94] mr-3"></span>
                    <p>Non RF</p>
                  </div>
                  {/* <p className='text-sm font-bold'>({data?.total_nonrf_int}) {(data?.total_nonrf_int * 100 / (data?.total_rf_int + data?.total_nonrf_int)).toFixed(0)}%</p> */}
                  <p className="text-sm font-bold">{data?.total_nonrf_int}</p>
                </div>
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-4 h-4 rounded-full bg-[#cad1e0] mr-3"></span>
                    <p>Total Keseluruhan</p>
                  </div>
                  <p className="text-sm font-bold">
                    {data?.total_rf_int + data?.total_nonrf_int}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-base font-bold">Realisasi Pendanaan</h3>
              <ReactApexChart
                options={optionsFunding}
                series={[
                  data?.total_realisasi_funding_int || 0,
                  data?.total_funding_int - data?.total_realisasi_funding_int,
                ]}
                type="donut"
              />
              <div className="mx-0 space-y-2 md:-mx-2 2xl:mx-0">
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-3.5 h-3.5 rounded-full bg-[#cad1e0] mr-1.5"></span>
                    <p className="text-sms md:text-xsm 2xl:text-sms">
                      Total Pendanaan
                    </p>
                  </div>
                  <p className="font-bold">
                    Rp{Helper.amount(data?.total_funding_int)}
                  </p>
                </div>
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-3.5 h-3.5 rounded-full bg-[#436B94] mr-1.5"></span>
                    <p className="text-sms md:text-xsm 2xl:text-sms">
                      Realisasi Pendanaan
                    </p>
                  </div>
                  <p className="font-bold">
                    Rp{Helper.amount(data?.total_realisasi_funding_int)}
                  </p>
                </div>
                <div className="flex items-center justify-between text-sm md:text-xs 2xl:text-sm">
                  <div className="flex items-center">
                    <span className="w-3.5 h-3.5 rounded-full bg-blue-500 mr-1.5"></span>
                    <p className="text-sms md:text-xsm 2xl:text-sms">
                      Sisa Pendanaan
                    </p>
                  </div>
                  <p className="font-bold">
                    Rp
                    {Helper.amount(
                      data?.total_funding_int -
                        data?.total_realisasi_funding_int,
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5">
        <div className="p-5 bg-white shadow-lg rounded-2xl">
          <h3 className="mb-4 text-base font-bold">Aktivitas Terbaru</h3>
          <div className="flex flex-col items-center justify-between w-full mb-5 space-y-3 md:w-max md:flex-row md:justify-center md:space-x-3 md:space-y-0 md:mb-4 2xl:mb-5">
            <Select
              name="farmer"
              styles={SelectCustomStyles}
              options={dataFarmer}
              onChange={setSelectedFarmer}
              isClearable
              placeholder="- Pilih Petani -"
              className="w-full md:w-[200px]"
              isLoading={loadingFarmer}
              getOptionValue={(option: any) => option["id_seq"]}
              getOptionLabel={(option: any) => option["fullname_var"]}
            />
            <Select
              name="land"
              styles={SelectCustomStyles}
              options={dataLand}
              onChange={setSelectedLand}
              isClearable
              placeholder="- Pilih Lahan -"
              className="w-full md:w-[200px] 2xl:md:w-[300px]"
              isLoading={loadingLand}
              getOptionValue={(option: any) => option["land_code_var"]}
              getOptionLabel={(option: any) =>
                `[${option["land_code_var"]}] ${option["land_name_var"]}`
              }
            />
            <Select
              name="period"
              styles={SelectCustomStyles}
              options={dataPeriod}
              onChange={setSelectedPeriod}
              isClearable
              placeholder="- Pilih Periode -"
              className="w-full md:w-[175px] 2xl:md:w-[200px]"
              isLoading={loadingPeriod}
              getOptionValue={(option: any) => option["period_plant_txt"]}
              getOptionLabel={(option: any) => option["period_plant_txt"]}
            />
            <button
              type="submit"
              className="w-full px-6 py-3 text-sm text-white bg-blue-600 rounded-lg md:w-max hover:bg-blue-500"
              onClick={fetchActivities}>
              Terapkan
            </button>
          </div>

          <TableMax dataTable={activities} columnTable={columns} />
        </div>
      </div>

      {loader && <Loader />}
    </>
  );
};

const Card = ({
  title,
  value,
  icon,
  color,
  addingStyleTitle,
  addingStyleValue,
}: any) => {
  return (
    <div className="flex items-center p-6 bg-white shadow-lg 2xl:p-8 rounded-2xl">
      <span
        className={`bg-${color}-100 text-${color}-500 w-12 h-12 flex justify-center items-center rounded-md text-lg mr-6`}>
        <i className={icon || "fa-solid fa-map-location"}></i>
      </span>
      <div>
        <h3 className={`font-bold ${addingStyleValue || "text-2xl"}`}>
          {value}
        </h3>
        <p className={`${addingStyleTitle}`}>{title}</p>
      </div>
    </div>
  );
};

export default DashbboardAdmin;
